.content {
    padding: 4rem !important;
}
.content h1{
    color: #000;
}
.content h2 {
    color: #000;
}
.w-landing {
    width: 80%;
}

@media(max-width: 1024.98px) {
    .content {
        padding: 1rem !important;
    }
}