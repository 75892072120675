.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}

.sty-col1 {
    /* width: 100%; */
    position: relative;
    user-select: none;
}

.sty-promo1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F7FA;
    padding: 25px;
}


/* Custom Arrows CSS */
.custom-arrow {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    transform: translateY(110%);
    z-index: 1;
}

.custom-left-arrow {
    left: -25px;
    background: url('../../assets/images/left-arrow.png') no-repeat center center;
    background-size: contain;
}

.custom-right-arrow {
    right: -25px;
    background: url('../../assets/images/right-arrow.png') no-repeat center center;
    background-size: contain;
}