.banner-v360 {
    background-size: cover;
    background-position: center;
    content: '';
    height: 580px;
    position: relative;
}

.w-35 {
 width: 35%;
}

.content-right {
    width: 100%;
    background: linear-gradient(270deg, #000000 -55%, rgba(0, 0, 0, 0) 48.64%);
    /* opacity: 0.5; */
}

.content-left {
    width: 100%;
    background: linear-gradient(90deg, #000000 -55%, rgba(0, 0, 0, 0) 56.17%);
    /* opacity: 0.5; */
}

.content-right h1,
.content-left h1 {
    right: 500px;
    color: #FFFFFF;
}

.content-right h2,
.content-left h2 {
    position: relative;
    top: -30px;
    line-height: 70px;
    color: #FFFFFF;
}


.content-padding {
    padding-left: 12rem;
    padding-right: 12rem;
}

@media(max-width: 1440px) {
    .content-padding {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}


@media(max-width: 1200px) {
    .content-padding {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .content-right h1,
    .content-left h1 {
        right: 500px;
        color: #FFFFFF;
        text-align: center;
    }

    .content-right h2,
    .content-left h2 {
        top: -20px;
        line-height: 25px;
        text-align: center;
    }

    .content-right,
    .content-left {
        width: 100%;
        background: #000000 !important;
        opacity: 0.5;
    }
    .content-right p,
    .content-left p
    {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .content-right .link,
    .content-left .link {
        display: flex;
        justify-content: center;
    }
    .banner-v360 {
        height: 350px !important;
    }
    .w-35 {
        width: 75%;
    }
}