#myBtn {
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 99;
    border: none;
    transition: box-shadow 100ms ease-in-out 0s;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-top: -2% !important;
}

#myBtn.at-bottom {
    bottom: 405px; /* Bottom position when at the bottom of the screen */
}

@media (max-width: 575px) {
    .wc-100 {
        width: fit-content !important;
    }
    #myBtn {
        height: 50px;
        width: 50px;
        bottom: 20px;
    }
    #myBtn.at-bottom {
        bottom: 20px;
    }
}

#myBtn {
    background-image: url('../../assets/images/up-arrow.png');
    background-position: 50%, 50%;
    background-size: 80%;
    background-repeat: no-repeat;
    z-index: 9999999;
}