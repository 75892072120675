.detail {
    margin-left: -30px !important;
    height: 380px !important;
    /* overflow: auto; */
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: #fff !important;
    border: 1px solid #b5b5b5;
}

.style-card-detail01 {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
}

.readmore {
    color: var(--main-green);
    text-align: right;
}

.slick-slide {
    padding: 0 10px; /* Add padding to create gaps */
}

.slick-list {
    margin-left: -10px !important; /* Offset the left padding */
    margin-right: -10px; /* Offset the right padding */
}

.slide-item .promo-content {
    display: none;    
    color: rgb(0, 0, 0);
    box-sizing: border-box;
    transition: opacity 0.3s ease;
    opacity: 0;
    position: absolute;
    background-color: #fff;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 95%;
}

.slide-item .promo-title {
    display: block;
    position: absolute;
    padding: 20px;
    bottom: 0;
    left: 0;
    margin-left: 5px;
    color: #fff;
    transition: opacity 0.3s ease;
    background-color: transparent;
    opacity: 1;
}

.slide-item:hover .promo-content {
    display: block;
    opacity: 1;
    z-index: 9999999999;
}

.slide-item:hover .promo-title {
    opacity: 0;
}

.slide-item .promo-content1 {
    display: block;
    color: rgb(0, 0, 0);
    background-color: #fff;
    padding: 30px;
    width: 80%;
    position: relative;
}

.sty-color01 {
    background-color: #65B562;
    padding: 5px 15px;
    width: fit-content;
    height: auto;
}

.sty-color02 {
    background-color: #D8EDD8;
    padding: 5px 15px;
    width: fit-content;
    height: auto;
}

.box-next {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%) !important;
    margin-left: -25px;
}
.box-pev {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%) !important;
    margin-right: -25px;
}
.box-arrow {
    position: absolute !important;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100%;
}

.box-arrow1 {
    position: absolute !important;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100%;
}

.box-next1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%) !important;
    margin-left: 25px;
}
.box-pev1 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%) !important;
    margin-right: 25px;
}

.box-slider {
    position: absolute;
    background-color: #fff;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.promotion-slider {
    text-align: center;
    padding: 40px 20px;
}

.promotion-slider h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.slide {
    position: relative;
}

.promo-item {
    position: relative;
    text-align: left;
}

.promo-item img {
    width: 100%;
    height: auto;
}

.promo-content {
    padding: 20px;
}

.promo-content h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.promo-content p {
    margin-bottom: 10px;
}

button {
    background-color: #3EA33A;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #2b7229;
}

.box-arr01 {
    position: absolute;
    top: 40%;
    left: 0;
    transform: translate(-150%, -50%);
}

.box-arr02 {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translate(150%, -50%);
}

@media(max-width: 1440px) {
    .slide-item .promo-content {
        padding: 15px;
        width: 95%;
    }
}

@media(max-width: 1199px) {
    .detail {
        height: 320px !important;
    }
}

@media(max-width: 1024px) {
    .detail {
        width: 100% !important;
        margin-left: 0px !important;
    }
}

@media(max-width: 768px) {
    .slide-item .promo-title {
        padding: 5px;
    }
    .slide-item .promo-content {
        padding: 10px;
        width: 95%;
    }
    .slide-item .promo-content1 {
        padding: 15px;
    }
}