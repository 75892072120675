.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    font-family: 'db_heaventregular' !important;
    font-size: 0.9rem !important;
    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}