.btn-bidding01 {
    background-color: #ffffff;
    border: 1px solid #929497;
    border-radius: 10px;
    color: #000000;
    height: auto;
    padding: 15px 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 305px;
}
.btn-bidding01.active {
    background-color: #3ea33a;
    border: 1px solid #3ea33a;
    color: #fff;
}
.btn-bidding01:hover {
    background-color: #3ea33a;
    border: 1px solid #3ea33a;
    color: #fff;
}
.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}

@media screen and (max-width: 930px) {
    .btn-bidding01 {
        width: 100%;
        padding: 15px;
    }
    
}

@media screen and (max-width: 380px) {
    .btn-bidding01 {
        width: 100%;
        padding: 10px;
    }
    
}