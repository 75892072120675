.box-careers-now {
    background-color: #F4F7FA;
    width: 80%;
    padding: 30px;
    height: auto;
    border: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-careers {
    position: absolute;
    top: 50%;
    left: 48% !important;
    transform: translate(-50%, -50%) !important;
}

.inpSearch {
    border: 1px solid #BBBDBF;
    padding: 10px;
    background-color: white;
    border-radius: 10px 0 0 10px;
    width: 80%;
    height: 45px;
}

.style-qual {
    width: 99% !important;
    margin-left: 10px !important;
    margin-top: 25px !important;
    padding: 20px 0 0 0 !important;
}

.btn-job-search {
    border: none;
    border-radius: 0 10px 10px 0;
    background-color: #3EA33A;
    padding: 10px;
    width: -moz-fit-content;
    width: fit-content;
    height: 45px;
    color: white;
}
.btn-job-search:hover {
    background-color: #2d772b;
}

.btn-apply {
    border: 1px solid #000;
    border-radius: 50px;
    background-color: transparent;
    padding: 7px 30px;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    color: #000;
}
.btn-apply:hover {
    border: 1px solid #3EA33A;
    background-color: #3EA33A;
    color: white;
}

.clearButton {
    position: absolute;
    bottom: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    right: 0;
    transform: translate(-55%, 0%) !important;
}

.sty-pdpa {
    height: 40vh;
    overflow-y: scroll;
}

button.clearButton:hover {
    background-color: transparent !important;
}

.bd-bottom-pdpa {
    border-bottom: 6px solid #3EA33A;
    width: 100px;
    position: relative;
}

.btn-cancel01 {
    background-color: white !important;
    border: 1px solid #929497 !important;
    border-radius: 10px !important;
    padding: 10px 40px !important;
    width: fit-content !important;
    height: auto !important;
    color: #000 !important;
}
.btn-cancel01:hover {
    background-color: #e4e4e4 !important;
    border: 1px solid #e4e4e4 !important;
}

.btn-agree01 {
    background-color: #3EA33A !important;
    border: 1px solid #3EA33A !important;
    border-radius: 10px !important;
    padding: 10px 40px !important;
    width: fit-content !important;
    height: auto !important;
    color: #ffffff !important;
}
.btn-agree01:hover {
    background-color: #2d772b !important;
    border: 1px solid #2d772b !important;
}

.box-title-form {
    background-color: #D8EDD8;
    border: 1px solid #D8EDD8;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    height: auto;
}

.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}

@media screen and (max-width: 1199px) {
    .box-careers-now {
        width: 90%;
    }
}

@media screen and (max-width: 930px) {
    .box-careers-now {
        width: 100%;
        padding: 20px;
    }
    .style-qual {
        width: 93% !important;
    }
}