.drpdw-sportName, .drpdw-status {
    position: relative;
    width: 100%;
    height: 64px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #D1D3D4;
}

.select-menu {
    width: 100%;
    cursor: pointer;
    position: relative;
    border: 1px solid #dbdbdb;
    height: 40px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    border-radius: 0.25rem;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
}

.select {
    width: 100%;
    background-color: #fff;
    color: #000000;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    border-bottom: 1px solid #D1D3D4 !important;
    border-radius: 0;
    padding: 3px 5px !important;
}

.options-list {
    position: absolute;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-top: 1px;
    padding: 0 20px;
    max-height: 180px;
    overflow-y: auto;
    height: 0;
    z-index: 999;
}

.option {
    padding: 8px 0;
    font-weight: 500;
    word-break: break-word;
}

.option:hover, 
.option.select {
    background-color: #5798F6;
    margin: 0 -20px;
    padding: 8px 20px;
    color: #fff;
}

.options-list::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
    border-radius: 10px;
}

.options-list::-webkit-scrollbar-thumb {
    background-color: #006ca2;
    border-radius: 10px;
}

.options-list.active {
    height: auto;
    border: 1px solid #dddddd;
}

.inp-dropdown {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 40px;
    padding: 10px;
}

.ic-dropdown {
    width: 48px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}