.main-heading h1 {
    font-size: 4em;
}
.main-heading h2 {
    font-size: 3em;
    position: relative;
    top: -6;
    /* border-bottom: 6px solid #3EA33A; */
    font-weight: 100
}

.pdt-hd {
    padding-top: 3rem !important;
}

.pdb-hd {
    padding-bottom: 3rem !important;
}

.main-heading .title-border {
    border-bottom: 6px solid #3EA33A;
    margin-top: clamp(-3rem, 3vw, -3rem) !important;
    height: clamp(4rem, 7vw, 7rem) !important;
    line-height: 2;
}

@media(max-width: 1600px) {
    .main-heading .title-border {
        margin-top: clamp(-2.2rem, 3vw, -3rem) !important;
    }
}

@media(max-width: 1400px) {
    .main-heading .title-border {
        margin-top: clamp(-1.5rem, 3vw, -3rem) !important;
    }
}

@media(max-width: 1024.98px) {
    .main-heading h1 {
        font-size: 2.5em;
    }

    .main-heading h2 {
        font-size: 2em;
        position: relative;
        top: -6;
        border-bottom: 6px solid #3EA33A;
        font-weight: 100
    }
    .pdt-hd {
        padding-top: 2rem !important;
    }
    
    .pdb-hd {
        padding-bottom: 1rem !important;
    }
}