.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}

.title-btn2 {
    margin-top: 0;
    z-index: 9;
    position: relative;
}

.w-75-ne {
    width: 75%;
}

@media screen and (max-width: 768px) {
    .w-75-ne {
        width: 100%;
    }
}