button.btn-share {
    background-color: transparent;
    color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    border-radius: 5px;
    width: 25px;
    height: 25px;
}

.ic-social {
    width: 25px !important;
    height: 25px !important;
}