div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 1.875em;
    font-family: 'db_heavent_cond' !important;
}

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 50px !important;
    background: initial;
    background-color: #5FB045 !important;
    color: #fff;
    font-size: 1em;
    width: 100px !important;
    font-family: 'db_heavent_cond' !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgba(17, 153, 62, 0.5) !important;
}

.swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 50px !important;
    background: initial;
    background-color: rgb(103, 108, 111);
    color: #fff;
    font-size: 1em;
    width: 100px !important;
    font-family: 'db_heavent_cond' !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:focus {
    box-shadow: 0 0 0 3px rgba(103, 108, 111, 0.5) !important;
}