.list-detail {
    border-top: 1px solid #BBBDBF;
    /* border-bottom: 1px solid #BBBDBF; */
    padding: 20px 0;
}

.inner-circle {
    width: 30px;
    height: 30px;
    background-color: transparent; /* Set your desired background color */
    color: #231F20; /* Set your desired text color */
    border-radius: 50%;
    border: 1px solid #231F20;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.3s ease;
}

.w-95 {
    width: 95%;
}

.btn-bidding {
    border: none;
    border-radius: 10px;
    background-color: #3EA33A;
    padding: 10px;
    width: fit-content;
    height: auto;
    color: white;
}
.btn-bidding:hover {
    background-color: #2d772b;
}
.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}