.container-footer {
    background: #242932;
    height: auto;
    color: #D0D2D3;
    padding: 8rem auto;
}

.container-footer h5 { 
    font-family: 'db_heaventregular' !important;
    font-size: clamp(0.8rem, 1vw, 1.1rem) !important;
}

.border-section-color {
    border-color: #414042 !important;
}

.custom-list ul {

    padding: 0;
    margin: 0;
}

.custom-list ul li {
    list-style-type: none !important;
}

.custom-list  a {
    text-decoration: none !important;
    font-family: 'db_heaventlight' !important;
    color: #D0D2D3;
    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
    line-height: 25px !important;
}

.subscribe {
    border: 0.5px solid #414042 !important;
    color: #fff !important;
    background-color: transparent !important;
    border-radius: 5px;
    padding: .75rem;
}

.contact-section {
    border-left: 1px solid #414042 !important;
    border-right: 1px solid #414042 !important;
}

.container-footer p{
    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
    font-family: 'db_heaventlight' !important;
}

.contact a, 
.contact p
{
    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
    font-family: 'db_heaventregular' !important;
}

.center-footer {
    display: block;
}

.mgl-18 {
    margin-left: 0;
}

.logo-asp-ft {
    text-align: left;
    -ms-grid-column: 1;
    width: 110px;
    height: auto;
    object-fit: contain;
}

@media(max-width: 1024.98px) {
    .contact-section {
        border: none !important;
    }
    .center-footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo-asp-ft {
        width: 25% !important;
    }
    .mgl-18 {
        margin-left: -18px;
    }
}