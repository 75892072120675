.remove-file {
    background-color: #be1e2d;
    border: 1px solid #be1e2d;
    border-radius: 50px;
    height: 25px;
    margin-right: -10px;
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    padding: 0 !important;
}
.remove-file:hover {
    background-color: #be1e2d;
    border: 1px solid #be1e2d;
}

.thumb-file {
    margin-bottom: 0px;
    margin-left: 20px;
    width: 100%;
    height: 70px;
    position: relative;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.center-box {
    height: 70px;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}