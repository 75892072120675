.txt-underline {
    text-decoration: underline;
}

.inp01 {
    width: 100%;
    background-color: #fff;
    color: #000000;
    height: 42px;
    border: none;
    border-bottom: 1px solid #D1D3D4 !important;
    border-radius: 0;
    padding: 3px 5px !important;
}

#textDetail {
    width: 100% !important;
    border: 1px solid #D1D3D4 !important;
    padding: 10px;
}

.btn-send {
    width: 230px;
    height: auto;
    background-color: #3EA33A;
    border: none;
    border-radius: 6px;
    padding: 10px;
    color: #fff;
}
.btn-send:hover {
    background-color: #2d772b;
}

.ic-arr {
    width: 27px;
    height: auto;
    object-fit: contain;
    margin-left: 10px;
}