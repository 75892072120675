.video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .video-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}
.title-btn {
  margin-top: -50px;
  z-index: 9;
  position: relative;
  width: 50%;
}

.box-form {
  border: 1px solid #929497;
  padding: 35px;
  width: 80%;
  height: auto;
}

.box-unit {
  width: 100%;
  height: 370px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 15px 15px 15px 120px;
}

.box-unit01 {
  width: 100%;
  padding: 15px 15px 15px 120px;
}

.me-6 {
    margin-right: 5.5rem !important;
}
.order-text01 {
  order: 2;
}
.order-img01 {
  order: 1; /* Image second */
}

.order-text02 {
  order: 1; /* Text first */
}

.order-img02 {
  order: 2; /* Image second */
}

.col-unit {
  flex: 0 0 auto;
  width: 33%;
}
@media(max-width: 1780px) {
  .title-btn {
    width: 70%;
  }
}

@media(max-width: 1600px) {
  .box-unit, .box-unit01 {
    padding: 15px 15px 15px 85px;
  }
}

@media (max-width: 1400px) {
  .box-unit, .box-unit01 {
      padding: 15px 15px 15px 70px;
  }
}

@media(max-width: 1270px) {
  .title-btn {
    width: 90%;
  }
}

@media(max-width: 1150px) {
  .title-btn {
    margin-top: -30px;
    z-index: 9;
    position: relative;
    width: 100%;
  }
  .box-unit, .box-unit01 {
    padding: 15px 15px 15px 45px;
}
}

@media(max-width: 991px) {
  .order-text01 {
    order: 2;
  }
  .order-img01 {
    order: 1; /* Image second */
  }
  .order-text02 {
    order: 2;
  }
  .order-img02 {
    order: 1; /* Image second */
  }
  .box-unit, .box-unit01 {
    padding: 15px;
}
}

@media(max-width: 768px) {
  .box-form {
    padding: 20px;
    width: 90%;
  }
}