.image-gallery-wrapper {
    width: 100%;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh) !important;
}
.image-gallery-play-button {
    left: unset !important;
    right: 0 !important;
}
.image-gallery-fullscreen-button, .image-gallery-play-button {
    bottom: 25px !important;
}
.image-gallery-bullets {
    width: auto !important;
    padding: 0 20px !important;
}
.image-gallery-bullets .image-gallery-bullets-container {
    text-align: right !important;
}
.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
    background: transparent !important;
    top: 50% !important;
    bottom: unset !important;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    text-align: start !important;
}

.bt-line-green {
    border-bottom: 6px solid #3EA33A;
    width: 26%;
}

.h-800 {
    height: 600px;
}

@media (max-width: 1440px){
    .h-800 {
        height: 500px;
    }
}

@media screen and (max-width: 1220px) {
    .h-800 {
        height: 350px;
    }
}

@media (max-width: 930px){
    .im-banner {
        margin-top: 50px !important;
    }
}

@media (max-width: 768px){
    .image-gallery-slide .image-gallery-description {
        bottom: 30px !important;
        font-size: .8em;
        padding: 8px 15px;
    }
    .h-800 {
        height: 310px;
    }
}