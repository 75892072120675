html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  overflow-x: visible;
  font-size: 18px;
}

.default-font p,
.default-font h1,
.default-font h2,
.default-font h3,
.default-font h4,
.default-font h5,
.default-font a,
.default-font ul,
.default-font div,
.default-font li {
  font-family: 'db_heaventlight';
}

.contain {
  display: flex;
  flex-wrap: wrap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }
}

.container01 {
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.ms-col-1 {
  -ms-grid-column: 1;
}

.ms-col-2 {
  -ms-grid-column: 2;
}

.ms-col-3 {
  -ms-grid-column: 3;
}

.ms-col-4 {
  -ms-grid-column: 4;
}

.ms-col-5 {
  -ms-grid-column: 5;
}

.ms-col-6 {
  -ms-grid-column: 6;
}

.ms-col-7 {
  -ms-grid-column: 7;
}

.ms-col-8 {
  -ms-grid-column: 8;
}

.fontReg {
  font-family: 'db_heaventregular' !important;
}

.fontUlLight {
  font-family: 'db_heaventullight' !important;
}

.fontLight {
  font-family: 'db_heaventlight' !important;
}

.fontMed {
  font-family: 'db_heaventmed' !important;
}

.fontBold {
  font-family: 'db_heaventbold' !important;
}

.font-size01 {
  font-size: 1rem !important;
  font-size: clamp(0.9rem, 1vw, 1rem) !important;
}

.font-size02 {
  font-size: 1.1rem !important;
  font-size: clamp(0.75rem, 1.5vw, 1.1rem) !important;
}

.font-size03 {
  font-size: 0.9rem !important;
  font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
}

.font-size04 {
  font-size: 2rem !important;
  font-size: clamp(1.1rem, 2.5vw, 2rem) !important;
}

.font-size05 {
  font-size: 1.5rem !important;
  font-size: clamp(0.8rem, 2.5vw, 1.5rem) !important;
}

.font-size06 {
  font-size: 2.2rem !important;
  font-size: clamp(1.2rem, 2.5vw, 2.2rem) !important;
}

.font-size07 {
  font-size: 1.8rem !important;
  font-size: clamp(0.9rem, 1.5vw, 1.8rem) !important;
}

.font-size08 {
  font-size: 1.3rem !important;
  font-size: clamp(0.9rem, 1.3vw, 1.3rem) !important;
}

.font-size09 {
  font-size: 6rem !important;
  font-size: clamp(1.7rem, 6vw, 6rem) !important;
}

.font-size10 {
  font-size: 4rem !important;
  font-size: clamp(1.4rem, 4vw, 4rem) !important;
}

.font-size11 {
  font-size: 4rem !important;
  font-size: clamp(1.2rem, 1vw, 4rem) !important;
}

.font-size12 {
  font-size: 1.3rem !important;
  font-size: clamp(0.7rem, 1vw, 1.3rem) !important;
}

.font-size13 {
  font-size: 5rem !important;
  font-size: clamp(2.5rem, 5vw, 5rem) !important;
}

.font-size14 {
  font-size: 3rem !important;
  font-size: clamp(1.5rem, 3vw, 3rem) !important;
}


.colorBlack {
  color: #000000;
}

.colorWhite {
  color: white;
}

.colorRed {
  color: #eb0019;
}

.colorGreen01 {
  color: #3EA33A;
}

.colorGray {
  color: #414042;
}

.colorGray01 {
  color: #808184;
}

.zIndex {
  z-index: 9999;
}

.lineHeight {
  line-height: 1.6em;
}

.order-text03 {
  order: 2;
}
.order-img03 {
  order: 1; /* Image second */
}

.order-text04 {
  order: 1; /* Text first */
}

.order-img04 {
  order: 2; /* Image second */
}

/* Ellipsis.css */
.multi-line-ellipsis {
  width: 90%;           /* Set the desired width */
  height: auto;          /* Height should match the number of lines * line-height */
  line-height: 1.5em;     /* Set the line-height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;  /* Number of lines to show */
  text-overflow: ellipsis;
  word-break: break-word;
}

/* Ellipsis.css */
.multi-line-ellipsis02 {
  width: 90%;           /* Set the desired width */
  height: auto;          /* Height should match the number of lines * line-height */
  line-height: 1.5em;     /* Set the line-height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;  /* Number of lines to show */
  text-overflow: ellipsis;
  word-break: break-word;
}

.multi-line-ellipsis07 {
  height: auto;          /* Height should match the number of lines * line-height */
  line-height: 1.5em;     /* Set the line-height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;  /* Number of lines to show */
  text-overflow: ellipsis;
  word-break: break-word;
}

.btn-seeBG {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  width: fit-content;
  height: auto;
  color: #000000;
  padding: 0;
}

.btn-seeBG:hover {
  background-color: transparent;
  border: none;
}

.mx-10 {
  margin: 0 10rem;
}

.px-10 {
  padding: 0 10rem;
}

.px-20 {
  padding: 0 20rem;
}

.box-bg {
  background-color: #000000 !important;
  opacity: 0.2 !important;
  width: 100% !important;
  height: 600px !important;
  cursor: pointer;
  position: absolute;
  display: block;
}
.click-vr {
  height: 600px !important;
}

.cursor-click {
  position: absolute;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  cursor: pointer;
  display: block;
  width: 120px;
  height: auto;
}

.small{
  width: 6px;
  height: 6px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.big{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 20rem;
  animation-name: stretch;
            animation-duration: 2.0s;
            animation-timing-function: ease-out;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-play-state: running;
}

.img-style02 {
  width: 80%;
  height: auto;
  object-fit: contain;
}


@keyframes stretch {
            0% {

                opacity: 0.2;
                background-color: green;
                border-radius: 100%;
            }

            50% {
                background-color: orange;
            }

            100% {
                background-color: red;
            }
        }

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(122, 122, 122); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c5c5c; 
}

.banner-title {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img-style {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.cover01 {
  width: 960px;
  height: 460px;
  object-fit: cover;
}

.cover02 {
  width: 100%;
  height: 460px;
  object-fit: contain;
}

.img-style01 {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.img-style-cv01 {
  width: 100% !important;
  height: 460px !important;
  object-fit: contain !important;
}

.img-style-cv02 {
  width: 100% !important;
  height: 460px !important;
  object-fit: cover !important;
}

.img-style-cv03 {
  width: 100% !important;
  height: 460px !important;
  object-fit: cover !important;
}

.img-style-card01 {
  width: 100% !important;
  height: 500px !important;
  object-fit: cover !important;
}

.img-style-card02 {
  width: 760px !important;
  height: 450px !important;
  object-fit: cover !important;
}

.img-style-card04 {
  width: 100% !important;
  height: -webkit-fill-available !important;
  object-fit: cover !important;
}

.img-style-card07 {
  width: 100% !important;
  height: 500px !important;
  object-fit: cover !important;
}

.img-style-card05 {
  width: 100% !important;
  height: 300px !important;
  object-fit: cover !important;
}

.img-style-card06 {
  width: 100% !important;
  height: 600px !important;
  object-fit: cover !important;
}

.txt-banner {
  position: absolute;
  top: 40%;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  width: max-content;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.custom-left-arrow:hover,
.custom-right-arrow:hover,
.image-gallery-play-button:hover {
    background-color: transparent !important;
}

.btn-see {
  background-color: #3EA33A;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}

.bd-bottom-banner {
  border-bottom: 6px solid #3EA33A;
  width: 25%;
  position: absolute;
  top: 95%;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-90 {
  width: 92% !important;
}

.w-card01 {
  width: 100% !important;
}

.btn-outline {
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  height: 40px;
  width: 40px
}

.btn-lang {
  border: none;
  background: transparent;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 10px !important;
}

.btn-lang:hover {
  border: none;
  background: transparent;
  text-decoration: underline;
}

.bg-light-white {
  background-color: var(--light-white);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.color-light-white {
  color: var(--light-white);
}

.bg-main-green {
  background-color: var(--main-green);
}

.color-main-green {
  color: var(--main-green);
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.input-default {
  background-color: #fff;
  border: 1px solid #bbbdbf;
  border-radius: 10px;
  height: 45px;
  padding: 10px;
  width: 100%;
}

.input-office {
  background-color: #fff;
  border: 1px solid #bbbdbf;
  border-radius: 0px;
  height: 45px;
  padding: 10px;
  width: 100%;
}

.mt-6 {
  margin-top: -6px !important;
}

.mt-46 {
  margin-top: -46px;
}

.mt-7 {
  margin-top: 7rem;
}

.wc-100 {
  width: 100% !important;
}

.bg-sty01 {
  background-color: #F4F7FA;
}
.bg-sty02 {
  background-color: #F4F7FA;
  padding: 50px 0;
}

.bd-bottom02 {
  border-bottom: 6px solid #3EA33A;
  width: 35%;
  padding: 10px 0;
}
.bd-bottom03 {
  border-bottom: 4px solid #000000;
  width: 50%;
  padding: 1px 0;
  display: block;
}
.bd-bottom04 {
  border-bottom: 2px solid #000000;
  width: 100%;
  padding: 1px 0;
  display: block;
}
.bd-bottom05 {
  border-bottom: 2px solid #000000;
  width: 55%;
  padding: 1px 0;
  display: block;
}
.bd-bottom06 {
  border-bottom: 6px solid #3EA33A;
  width: 15%;
  padding: 5px 0;
}

.mb-6 {
  margin-bottom: -6px;
}

.mb-13 {
  margin-bottom: -13px;
}

.pd-bn {
  margin-top: 70px;
  width: 100%;
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* @media screen and (max-width: 1700px) {
  .img-style-card02 {
    width: 600px !important;
    height: 400px !important;
  }
} */

@media screen and (max-width: 1440px) {
  .box-bg {
    height: 500px !important;
  }
  .click-vr {
    height: 500px !important;
  }
  .img-style-card02 {
    width: 645px !important;
    height: 415px !important;
  }
  .img-style-card05 {
    height: 200px !important;
  }
  .img-style-card06 {
    height: 300px !important;
  }
}

@media screen and (max-width: 1400px) {
  .px-20 {
    padding: 0 10rem;
  }
}
@media screen and (max-width: 1220px) {
  .im-banner, video.im-banner {
    height: 350px !important;
  }
  .pd-bn {
    height: 350px !important;
  }
  .box-bg {
    height: 350px !important;
  }
  .cursor-click {
    width: 85px !important;
  }
  .click-vr {
    height: 350px !important;
  }
}

@media screen and (max-width: 1199px) {
  .img-style-card02 {
      width: 100% !important;
      height: 415px !important;
  }
  .bg-sty02 {
    padding: 0.2rem 0 0 0 !important;
  }
  .w-card01 {
    width: 80% !important;
  }
  .mt-46 {
    margin-top: -36px;
  }
  .order-text03 {
    order: 2;
  }
  .order-img03 {
    order: 1; /* Image second */
  }
  .order-text04 {
    order: 2; /* Text first */
  }
  .order-img04 {
    order: 1; /* Image second */
  }
}

@media screen and (max-width: 930px) {
  .btn-lang {
    color: rgb(0, 0, 0);
  }
  .pd-bn {
    margin-top: 0px;
  }
  #header.scrolled {
    background-color: #ffffff !important;
    opacity: 1;
  }
  .mx-10 {
    margin: 0 2rem;
  }
  .px-10, .px-20 {
    padding: 0 2rem;
  }
  .txt-banner {
    top: 50%;
  }
  .banner-title {
    margin-top: 50px !important;
  }
  .cover01 {
    width: auto;
  }
  .img-style-cv01 {
    height: 230px !important;
  }
  .img-style-cv03 {
    height: 230px !important;
  }
  .img-style-card01 {
    height: 330px !important;
  }
  .img-style-card04 {
    height: 250px !important;
  }
  .img-style-card07 {
    height: 250px !important;
  }
}

@media (max-width: 768px){
  .box-bg {
      height: 310px !important;
  }
  .click-vr {
    height: 310px !important;
  }
  .cursor-click {
    top: 40% !important;
  }
  .img-style-cv02 {
    height: 350px !important;
  }
}

/* Mobile styles */
@media (max-width: 600px) {
  .im-banner, video.im-banner {
    height: 350px !important;
  }
  .pd-bn {
    height: 400px !important;
  }
  .video-wrapper video {
    height: 350px !important;
  }
  .image-gallery-slide .image-gallery-description {
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .cursor-click {
    width: 70px;
  }
  .px-10, .px-20 {
    padding: 0 1rem;
  }
  .img-style-cv02 {
    height: 250px !important;
  }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 1024px) {
  .im-banner, video.im-banner {
    height: 450px !important;
  }
  .video-wrapper video {
    height: 450px !important;
  }
  .pd-bn {
    height: 500px !important;
  }
  .image-gallery-slide .image-gallery-description {
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .cursor-click {
    width: 90px;
  }
}

@media (max-width: 575px) {
  .wc-100 {
    width: fit-content !important;
  }
}