.im-banner {
    width: 100% !important;
    height: 600px;
    object-fit: cover;
}

.sty-map {
    display: flex;
    justify-content: end;
    padding-right: 50px;
    padding-top: 50px;
}

a.cont:nth-child(1), a.cont:nth-child(2) {
    border-right: 1px solid #cdcdcd;
    width: auto;
    height: auto;
    padding-right: 13%;
}
.contact-list-sty {
    flex-direction: row;
}

@media screen and (max-width: 426px) {
    a.cont:nth-child(1), a.cont:nth-child(2) {
        padding-right: 6%;
    }
}

@media screen and (max-width: 392px) {
    a.cont:nth-child(1), a.cont:nth-child(2) {
        padding-right: 2%;
    }
}

@media screen and (max-width: 343px) {
    .contact-list-sty {
        flex-direction: column;
    }
    a.cont:nth-child(1), a.cont:nth-child(2) {
        border-right: none;
    }
}