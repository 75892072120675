#header {
    width: 100%;
    height: 70px;
    background-color: white;
    position: fixed;
    z-index: 99;
}
#header.scrolled {
    background-color: #ffffff !important;
    opacity: 0.7;
}

#header.scrolled ul.menu li.ms-col-2.subnav.subnav-content {
    background-color: #ffffff !important;
    /* opacity: 1; */
}

.bg-trans {
    background-color: linear-gradient(180deg, rgb(87 87 87 / 19%) 0%, rgb(255 255 255 / 0%) 100%) !important;
}

.nav-grid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 15% 2fr 12% 5%;
    -ms-grid-columns: 15% 2fr 12% 5%;
    align-items: center;
    text-align: center;
    height: 70px;
    background: transparent;
    z-index: 99999999999;
    position: relative;
}

ul.menu {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(7,auto);
    -ms-grid-columns: 1fr 1.3fr 2.8fr 1.8fr 1fr 1.4fr 1.4fr;
    -ms-grid-column: 2;
    margin: 0 5% 0 5%;
    height: 70px;
    align-items: center;
    text-align: center;
}

ul.menu li {
    list-style: none;
}

ul.menu li a {
    text-decoration: none;
    font-size: 1.1rem;
    font-size: clamp(0.7rem, 1vw, 1.1rem);
    color: rgb(0, 0, 0);
}

ul.menu li a:hover {
    color: #2D8929;
    text-decoration: underline;
}

ul.menu li .subnav {
    float: inherit;
    overflow: hidden;
}

ul.menu li .subnav .subnavbtn {
    font-size: 1.1rem;
    font-size: clamp(0.7rem, 1vw, 1.1rem);
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background-color: transparent;
    height: 70px;
}

ul.menu li .subnav:hover .subnavbtn {
    color: #2D8929;
    text-decoration: underline;
}


ul.menu li .subnav .subnav-content {
    display: none;
    position: fixed;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    z-index: 1;
    grid-template-columns: 20% repeat(4, 1fr) 20%;
    -ms-grid-columns: 20% 1fr 1fr 1fr 1fr 20%;
    margin: auto;
    align-items: center;
    height: 70px;
    opacity: 1;
}

ul.menu li .subnav .subnav-content a {
    float: left;
    color: rgb(0, 0, 0);
    text-decoration: none;
    opacity: 1;
}

ul.menu li .subnav .subnav-content a:hover {
    color: #000000;
    text-decoration: underline;
}

ul.menu li .subnav .subnav-content a {
    text-decoration: none;
    font-size: 1.4rem;
    font-size: clamp(0.7rem, 1.5vw, 1.4rem);
    color: rgb(0, 0, 0);
}

ul.menu li .subnav:hover .subnav-content {
    display: grid;
    display: -ms-grid;
}

.subnav-content {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-column: 1;
    margin: auto;
    text-align: center;
}

.subnavbtn:focus {
    color: #2D8929 !important;
}

.subnavbtn:focus+.subnav-content {
    display: grid !important;
}

ul.menu li .subnav .subnav-content1 {
    display: none;
    position: fixed;
    left: 0;
    background-color: rgb(0, 0, 0);
    width: 100%;
    z-index: 1;
    grid-template-columns: 20% repeat(5, 1fr) 20%;
    -ms-grid-columns: 20% 1fr 1fr 1fr 1fr 1fr 20%;
    margin: auto;
    align-items: center;
    height: 70px;
}

ul.menu li .subnav .subnav-content1 a {
    float: left;
    color: white;
    text-decoration: none;
}

ul.menu li .subnav .subnav-content1 a:hover {
    color: #2D8929;
    text-decoration: underline;
}

ul.menu li .subnav:hover .subnav-content1 {
    display: grid;
    display: -ms-grid;
}

.subnav-content1 {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(5, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-column: 1;
    margin: auto;
    text-align: center;
}

.subnavbtn:focus+.subnav-content1 {
    display: grid !important;
}

ul.menu li .subnav .subnav-content2 {
    display: none;
    position: fixed;
    left: 0;
    background-color: rgb(0, 0, 0);
    width: 100%;
    z-index: 1;
    grid-template-columns: 40% repeat(2, 1fr) 40%;
    -ms-grid-columns: 40% 1fr 1fr 40%;
    margin: auto;
    align-items: center;
    height: 70px;
}

ul.menu li .subnav .subnav-content2 a {
    float: left;
    color: white;
    text-decoration: none;
}

ul.menu li .subnav .subnav-content2 a:hover {
    color: #2D8929;
    text-decoration: underline;
}

ul.menu li .subnav:hover .subnav-content2 {
    display: grid;
    display: -ms-grid;
}

.subnav-content2 {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-column: 1;
    margin: auto;
    text-align: center;
}

.subnavbtn:focus+.subnav-content2 {
    display: grid !important;
}

ul.menu li .subnav .subnav-content3 {
    display: none;
    position: fixed;
    left: 0;
    background-color: rgb(0, 0, 0);
    width: 100%;
    z-index: 1;
    grid-template-columns: 15% repeat(6, 1fr) 15%;
    -ms-grid-columns: 15% 1fr 1fr 1fr 1fr 1fr 1fr 15%;
    margin: auto;
    align-items: center;
    height: 70px;
}

ul.menu li .subnav .subnav-content3 a {
    float: left;
    color: white;
    text-decoration: none;
}

ul.menu li .subnav .subnav-content3 a:hover {
    color: #2D8929;
    text-decoration: underline;
}

ul.menu li .subnav:hover .subnav-content3 {
    display: grid;
    display: -ms-grid;
}

.subnav-content3 {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(6, 1fr);
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-column: 1;
    margin: auto;
    text-align: center;
}

.subnavbtn:focus+.subnav-content3 {
    display: grid !important;
}

select {
    padding-right: 25px;
    border: 0;
    background: url(../../public/assets/images/icon02.png) no-repeat right transparent;
    background-size: 20px;
    -webkit-appearance: none;
    font-size: 1.2rem;
    font-size: clamp(0.9rem, 2.5vw, 1.2rem);
}

select:focus {
    border: none;
    outline: none;
}

/* Start Hamburger Menu */
.topnav {
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.topnav #myLinks {
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    position: fixed;
    width: 100%;
    height: 100%;
}

#myLinks {
    margin-top: -5px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
}

.topnav a, .topnav font, .topnav span {
    color: black;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 2rem;
    font-size: clamp(0.8rem, 1.5vw, 1.5rem);
    display: block;
    font-family: 'db_heaventregular';
}

.topnav a.icon {
    background: white;
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
}

.topnav a:hover {
    /* background-color: #ddd; */
    color: black;
}

.topnav a.active:hover {
    background-color: white;
}

.topnav a.active {
    margin-left: 40px;
    background-color: white;
}

.logo-asp {
    text-align: left;
    -ms-grid-column: 1;
    width: 110px;
    height: auto;
    object-fit: contain;
}

.topnav-grid a img.logo-asp {
    width: 70px;
    height: auto;
    object-fit: contain;
    margin-top: 0;
}

.stl-icon01 {
    width: 60%;
    height: auto;
    object-fit: contain;
}

.topnav-grid {
    display: grid;
    grid-template-columns: 6fr 1fr;
}

.topnav-grid .language a {
    color: #000;
    padding: 5px;
    font-size: clamp(0.9rem, 1vw, 1.5rem);
}

.topnav-grid .language a:hover {
    text-decoration: underline;
    color: #fff;
}

.topnav-grid .language a:active {
    text-decoration: underline;
    color: #fff;
}

.topnav-grid .btn-outline {
    border: 1px solid #000;
    border-radius: 50%;
    background-color: transparent;
    display: block;
    height: 30px;
    width: 30px;
}

.expand {
    line-height: 40px;
    font-size: 26px;
    float: right;
    margin-right: 20px;
    color: #b2d233;
}

#subMenu, #subMenu2, #subMenu3, #subMenu4, #subMenu5 {
    padding-left: 35px;
    display: none;
    list-style: none;
}

/* End Hamburger Menu */

.grid-dropdown {
    display: grid;
    grid-template-columns: 1fr 2fr;
    text-align: right;
    -ms-grid-column: 3;
    background-color: transparent;
}

.stl-ic-tel {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.language a {
    text-decoration: none;
    color: #fff;
}

.language a:hover {
    text-decoration: underline;
    color: #fff;
}

.language a:active {
    text-decoration: underline;
    color: #fff;
}

.bd-left {
    border-left: 1px solid #ddd;
}

.stl-ic-rearch {
    width: 40%;
    height: auto;
    object-fit: contain;
}

.stl-ic-multi {
    width: 30%;
    height: auto;
    object-fit: contain;
}

.d-desktop-menu {
    display: block;
}

.d-mobile-menu {
    display: none;
}

ul.menu-mobile {
    list-style: none !important;
    padding-left: 0 !important;
}

@media only screen and (max-width : 1800px) {
    .nav-grid {
        grid-template-columns: 10% 2fr 15% 7%;
        -ms-grid-columns: 10% 2fr 15% 7%;
    }
}

@media only screen and (max-width : 1640px) {

    ul.menu {
        /* grid-template-columns: 1fr 1fr 1fr 1.3fr;
        -ms-grid-columns: 1fr 1fr 1fr 1.3fr; */
        margin: 0 3% 0 0;
    }

    ul.menu li .subnav .subnav-content {
        grid-template-columns: 5% repeat(4, 1fr) 5%;
        -ms-grid-columns: 5% 1fr 1fr 1fr 1fr 5%;
    }

    ul.menu li .subnav .subnav-content1 {
        grid-template-columns: 5% repeat(5, 1fr) 5%;
        -ms-grid-columns: 5% 1fr 1fr 1fr 1fr 1fr 5%;
    }

    ul.menu li .subnav .subnav-content2 {
        grid-template-columns: 30% repeat(2, 1fr) 30%;
        -ms-grid-columns: 30% 1fr 1fr 30%;
    }

    ul.menu li .subnav .subnav-content3 {
        grid-template-columns: 5% repeat(6, 1fr) 5%;
        -ms-grid-columns: 5% 1fr 1fr 1fr 1fr 1fr 1fr 5%;
    }

    .grid-dropdown {
        grid-template-columns: 0.5fr 2fr;
    }

    .btn-outline {
        height: 30px !important;
        width: 30px !important;
    }
}

@media only screen and (max-width: 1440px) {
    .nav-grid {
        grid-template-columns: 10% 2fr 15% 10%;
        -ms-grid-columns: 10% 2fr 15% 10%;
    }

    ul.menu {
        margin: 0 1% 0 0;
    }

    .stl-ic-tel {
        width: 60%;
    }

    .logo-asp {
        width: 70%;
    }
}

@media only screen and (max-width : 930px) {

    .d-desktop-menu {
        display: none;
    }

    .d-mobile-menu {
        display: block;
    }

    #header {
        height: auto;
    }

    .nav-grid {
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
        height: auto;
    }

    ul.menu {
        grid-template-columns: 2fr 2fr 2fr 2.5fr;
        -ms-grid-columns: 2fr 2fr 2fr 2.5fr;
    }

    ul.menu li .subnav .subnav-content {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
    }

    ul.menu li .subnav .subnav-content1 {
        grid-template-columns: repeat(5, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    ul.menu li .subnav .subnav-content2 {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: 1fr 1fr;
    }

    ul.menu li .subnav .subnav-content3 {
        grid-template-columns: repeat(6, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}